export const theme = {
    colors: {
        primary: '#2F2F2F',
        primary_light: '#484848',
        secondary: '#1EBF85',
        white: '#FFFFFF',
        black: '#000',
        para_text_color: '#D2D2D2',
    },
    fonts: {
        family: 'DM Sans',
        weight: {
            regular: 400,
            medium: 500,
            bold: 700,
        }
    },
    breakpoints: {
        mobile: '720px',
    }
}