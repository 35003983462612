import React from 'react';
import { motion } from 'framer-motion';

//import global styles
import {
    FlexContainer,
    PaddingContainer,
    Heading,
    ParaText,
    BlueText,
    FlashySkillsCard,
    InnerLogoContainer,
} from '../styles/Global.styled';

//import My Skills styles
import {
    SkillsCardContainer,
} from '../styles/MySkills.styled';

import { Skills } from '../utils/Data';

import { fadeInLeftVariant, fadeInRightVariant } from '../utils/Variants';

const MySkills = () => {
    return(
        <PaddingContainer
            id = "Skills"
            top = "10%"
            bottom = "10%"
            responsiveLeft = "1rem"
            responsiveRight = "1rem"
        >
            <FlexContainer 
                responsiveFlex
                responsiveDirection = "column-reverse" 
                fullWidthChild
            >

                {/*--left-section--*/}
                <SkillsCardContainer
                    as = {motion.div}
                    variants = {fadeInLeftVariant}
                    initial = "hidden"
                    whileInView= "visible"
                >
                    {Skills.map((skill) => (
                        <FlashySkillsCard>
                            <InnerLogoContainer
                                size = "5rem"
                                color = "blue"
                            >
                                {skill.icon}
                            </InnerLogoContainer>

                            <Heading
                                as = "h4"
                                size = "h4"
                            >
                                {skill.tech}
                            </Heading>
                        </FlashySkillsCard>
                    ))}
                </SkillsCardContainer>
                
                {/*--right-section--*/}
                <motion.div
                    variants={fadeInRightVariant}
                    initial = "hidden"
                    whileInView= "visible"
                >
                    <Heading
                        as = "h4"
                        size = "h4"
                    >
                        MY SKILLS
                    </Heading>

                    <Heading
                        as = "h2"
                        size = "h2"
                        top = "0.5rem"
                    >
                        What <BlueText>I've Learned</BlueText>
                    </Heading>

                    <ParaText
                        top = "2rem"
                        bottom = "1.5rem"
                    >
                       As I am currently a custom low voltage systems technician at Wired & Secured Solutions I am certified in the management of hardware and software networking components and include IP configuration, setting up wireless and wired networks, managing networks, basic network security, software updates, hardware upgrades and network protocols. From my six years serving in the USAF, I've earned a degree from the Community College of the Air Force, various certifications that are required to become a craftsman in aircraft maintenance, and also graduated from Airman Leadership School for professional development.
                    </ParaText>

                    <ParaText>
                        Graphic design has been an interest of mine ever since I was a kid and found out you can make art digitally, therefore any chance I get I love making graphics for friends, family, and school projects. Graphic design was a factor in deciding to pursue a Computer Technology & Software Development Bachelor's degree at Valencia College, and I've learned a bit of Python, Java, JS, SQL, HTML, CSS, and React.
                    </ParaText>
                </motion.div>
            </FlexContainer>
        </PaddingContainer>
    )
}

export default MySkills