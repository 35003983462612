import styled from "styled-components";



export const ShowcaseParticleContainer = styled.div`
    position: relative;
    padding-left: 1rem;
    
    

    @media(max-width: ${({ theme }) => theme.breakpoints.mobile }){
        width: 95%;
        margin-top: 2rem;
        
    }
`

export const ShowcaseImageCard = styled.div`
    border: 1px-solid white;
    width: 100%;
    height: auto;
    padding-top: 1rem;
    padding-right: 1rem;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    @media(max-width: ${({ theme }) => theme.breakpoints.mobile }){
        width: 100%;
        margin-top: 2rem;
        
    }
`

export const Particle = styled.img`
    position: absolute;
    top: ${({ top }) => top};
    left: ${({ left }) => left};
    right: ${({ right }) => right};
    bottom: ${({ bottom }) => bottom};
    transform: rotate(${({ rotate }) => rotate});
`

export const StyledLink = styled.a`
    text-decoration: none;
    color: #fff;
    cursor: pointer;
`

