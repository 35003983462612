import React from 'react'
import { motion } from 'framer-motion'

//import global styles
import {
    PaddingContainer,
    Heading,
    BlueText,
    FlexContainer,
    SendButton,
} from '../styles/Global.styled';

//import footer styles
import {
    FormLabel,
    FormInput,
    ContactForm
} from '../styles/Footer.styled';

import { fadeInBottomVariant } from '../utils/Variants';

const Footer = () => {
    return (
        <PaddingContainer
            id = "ContactMe"
            top = "5%"
            bottom = "10%"
        >
            <Heading
                as = {motion.h4}
                variants = {fadeInBottomVariant}
                initial = "hidden"
                whileInView = "visible"
                size = "h4"
                align = "center"
            >
                MY CONTACT
            </Heading>

            <Heading
                as = {motion.h2}
                variants = {fadeInBottomVariant}
                initial = "hidden"
                whileInView = "visible"
                size = "h2"
                align = "center"
                top = "0.5rem"
            >
                Contact <BlueText>Me Here</BlueText>
            </Heading>

            <PaddingContainer
                top = "3rem"
            >
                <FlexContainer
                    justify = "center"
                >
                    <ContactForm
                        as = {motion.form}
                        variants = {fadeInBottomVariant}
                        initial = "hidden"
                        whileInView = "visible"
                        name = "contact"
                        method = "POST"
                        data-netlify = "true"
                        netlify-honeypot = "bot-field"
                    >
                        <input 
                            type = "hidden"
                            name = "bot-field"
                        />
                        <input
                            type = "hidden"
                            name = "form-name"
                            value = "contact"
                        />
                        <PaddingContainer
                            bottom = "2rem"
                        >
                            <FormLabel>Name:</FormLabel>
                            <FormInput
                                required
                                name = "name"
                                type = "text"
                                placeholder ="Enter your name"
                            />
                        </PaddingContainer>

                        <PaddingContainer
                            bottom = "2rem"
                        >
                            <FormLabel>Email:</FormLabel>
                            <FormInput
                                required
                                name = "email"
                                type = "email"
                                placeholder ="Enter your email"
                            />
                        </PaddingContainer>

                        <PaddingContainer
                            bottom = "2rem"
                        >
                            <FormLabel>Message:</FormLabel>
                            <FormInput
                                required
                                name = "message"
                                as = "textarea"
                                placeholder ="Enter your message"
                            />
                        </PaddingContainer>

                        <FlexContainer
                            justify = "center"
                            responsiveFlex
                        >
                            <SendButton
                                type = "submit"
                            >Send Message</SendButton>
                        </FlexContainer>
                    </ContactForm>
                </FlexContainer>
            </PaddingContainer>

        </PaddingContainer>
    )
}

export default Footer