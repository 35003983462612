import React from 'react'
import { motion } from 'framer-motion'

//import global styles
import {
    PaddingContainer,
    FlexContainer,
    Heading,
    ParaText,
    BlueText,
    IconContainer,
    LogoContainer,
    InnerLogoContainer,
} from '../styles/Global.styled';

//import showcase styles
import { 
    ShowcaseImageCard,
    ShowcaseParticleContainer,
    StyledLink
} from '../styles/Showcase.styled';

//import react-icons
import { 
    BsTwitter, 
    BsInstagram, 
    BsYoutube,
    BsLinkedin
} from "react-icons/bs";

//import asset
import WSSLogo from '../assets/WSSLogo.png';
import PitchersLogo from '../assets/PitchersLogo.png';
import FamilyPhoto from '../assets/family-photo.jpg';
import CTLogo from '../assets/CTLogo.png';
import Jetski from '../assets/jetski.jpg';
import Mountain from '../assets/travel.jpg';
import AirForce from '../assets/AirForce.jpg';
import Wife from '../assets/suit.jpg';
import { fadeInLeftVariant, fadeInRightVariant } from '../utils/Variants';

const slides = [
    AirForce,
    Wife,
    FamilyPhoto,
    Jetski,
    Mountain
];



const Showcase = () => {
    return(
        <PaddingContainer
            id = "Home"
            left = "3%"
            right = "10%"
            top = "18%"
            bottom = "10%"
            responsiveLeft = "1rem"
            responsiveRight = "1rem"
            responsiveTop = "10rem"
        >

            <FlexContainer align="center" fullWidthChild>
                {/* -- left-content-- */}
                <motion.div
                    variants = {fadeInLeftVariant}
                    initial = "hidden"
                    whileInView = "visible"
                >
                    <Heading as = "h4" size="h4">Hey, I'm</Heading>

                    <Heading
                        as="h2"
                        size="h2"
                        top="0.5rem"
                        bottom="1rem"
                    >
                        <BlueText>Cesar Tuesca</BlueText>
                        <ShowcaseImageCard>
                            <img 
                                src={FamilyPhoto} 
                                alt="Family Photo"
                                style = {{
                                    width: '100%',
                                    height: 'auto',
                                    borderRadius: '25px',
                                    border: '1px solid #fff',
                                    background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.4988399071925754) 25%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.5011600928074246) 75%, rgba(255,255,255,0) 100%)',
                                    backgroundClip: 'content-box'
                                }}
                            />
                        </ShowcaseImageCard>
                    </Heading>

                    {/*--social-icons--*/}
                    <FlexContainer 
                        gap="20px" 
                        responsiveFlex
                        justify="center"
                        alignItems="center"
                    >
                        <IconContainer 
                            color="white"
                            size="1.5rem"
                        >
                            <StyledLink href="https://www.linkedin.com/in/cesar-tuesca-8a6b72203"><BsLinkedin /></StyledLink>
                        </IconContainer>

                        <IconContainer 
                            color="white"
                            size="1.5rem"
                        >
                            <StyledLink href="https://twitter.com/ceseusa"><BsTwitter /></StyledLink>
                        </IconContainer>

                        <IconContainer 
                            color="white"
                            size="1.5rem"
                        >
                            <StyledLink href="https://www.youtube.com/@heythenamescesar"><BsYoutube /></StyledLink>
                        </IconContainer>

                        <IconContainer 
                            color="white"
                            size="1.5rem"
                        >
                            <StyledLink href="https://www.instagram.com/fathercese/"><BsInstagram /></StyledLink>
                        </IconContainer>
                    </FlexContainer>
                </motion.div>

                {/*--right-content--*/}
                <FlexContainer
                    justify = "flex-end"
                    as = {motion.div}
                    variants = {fadeInRightVariant}
                    initial = "hidden"
                    whileInView = "visible"
                    
                >
                    <ShowcaseParticleContainer>
                        <Heading
                            as="h3"
                            size="h3"
                        >
                            Student at <BlueText>Valencia College</BlueText>
                        </Heading>

                        <ParaText 
                            as="p"
                            top="2rem"
                            bottom="2rem"
                        >
                            I previously served as an Electrical & Environmental Craftsman in the United States Air Force, where I honed my skills in troubleshooting, task management, and professionalism for over six years. Now, I'm embracing a new chapter as a student at Valencia, where I'm eager to expand my knowledge and perfect my skills in my chosen field. I continue to uphold my strong work ethic, organization, and teamwork mentality, qualities that have served me well in my previous role and will continue to do so as I pursue my educational goals.
                        </ParaText>
                        <Heading
                            as = "h4"
                            size = "h4"
                            justify = "center"
                            bottom = "2rem"
                        >
                            GPA: <BlueText>3.75</BlueText> President's List: <BlueText>6x </BlueText> Bachelor's Degree: <BlueText>Fall '23</BlueText>
                        </Heading>
                            <Heading
                                as="h2"
                                size="h2"
                                top="0.5rem"
                                bottom="1rem"
                            >
                            <BlueText>Logos</BlueText>
                        </Heading>
                        <LogoContainer
                            background = "#fff"
                            gap = "2%" 
                            responsiveFlex
                            justify = "center"
                            alignItems ="center"
                        >
                            <InnerLogoContainer
                                responsiveFlex 
                                size="1.5rem"
                            >
                                <img 
                                src={WSSLogo}
                                width = "auto"
                                height = "95px"
                                alt="logo1"
                                />
                            </InnerLogoContainer>

                            <InnerLogoContainer 
                                responsiveFlex 
                                size="1.5rem"
                            >
                                <img 
                                src={CTLogo}
                                width = "auto"
                                height = "85px"
                                alt="logo2"
                                />
                            </InnerLogoContainer>

                            <InnerLogoContainer 
                                responsiveFlex
                                size="1.5rem"
                            >
                                <img
                                src={PitchersLogo}
                                width = "auto"
                                height = "85px"
                                justify = "center"
                                alignItems = "center"
                                alt="logo3"
                                />
                            </InnerLogoContainer>
                        </LogoContainer>

                        {/* <Particle 
                            //Motion Animation for Image
                            /*as = {motion.img}
                            animate = {{
                                x: [0, 100, 0],
                                rotate: 360,
                                scale: [1, 0.5, 1]
                            }}
                            transition = {{
                                duration: 20,
                                repeate: Infinity,
                            }}
                            src={WSSLogo}
                            width = "135px"
                            height = "auto"
                            alt="logo1"
                            top="400px"
                            left="200px"
                        />

                        <Particle 
                            //Motion Animation for Image
                            /*as = {motion.img}
                            animate = {{
                                y: [0, 100, 0],
                                rotate: 360,
                                scale: [1, 0.8, 1]
                            }}
                            transition = {{
                                duration: 18,
                                repeate: Infinity,
                            }}
                            src={USAFLogo}
                            width = "135px"
                            height = "auto"
                            alt="particle"
                            top="400px"
                            left="350px"
                        />

                        <Particle
                            //Motion Animation for Image
                            /*as = {motion.img}
                            animate = {{
                                y: [0, -100, 0],
                                rotate: 360,
                                scale: [1, 0.9, 1]
                            }}
                            transition = {{
                                duration: 15,
                                repeate: Infinity,
                            }}
                            src={PitchersLogo}
                            width = "135px"
                            height = "auto"
                            alt="particle"
                            bottom="10px"
                            left="-70px"
                            rotate="50deg"
                        /> */}
                    </ShowcaseParticleContainer>
                </FlexContainer>
            </FlexContainer>

        </PaddingContainer>
    )
}

export default Showcase