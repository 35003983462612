import Project1 from '../assets/WSSFigma.png';
import Project2 from '../assets/RNMTrivia.png';
import NetworkPRO from '../assets/NetworkPRO.png';
import USAFBadge from '../assets/maintenanceBadge.png';
import Designs from '../assets/graphicDesigns.png';
import Stack from '../assets/codeStack.png';

export const Skills = [
    {
        id: 0,
        tech: 'Network Pro',
        icon: 
        <img 
            src = {NetworkPRO}
            alt = "certification"
            height = '150px'
            width = 'auto'
        />
    },
    {
        id: 1,
        tech: 'USAF 2A676',
        icon: 
        <img
            src = {USAFBadge}
            alt = "badge"
            height = '150px'
            width = 'auto'
        />
    },
    {
        id: 2,
        tech: 'Graphic Design',
        icon: 
        <img 
            src = {Designs}
            alt = "designs"
            height = '150px'
            width = 'auto'
        />
    },
    {
        id: 3,
        tech: 'Software Development',
        icon: 
        <img
            src = {Stack}
            alt = "code"
            height = '150px'
            width = 'auto'
        />
    }
];

export const projectDetails = [
    {
        id: 0,
        project_name: "WSS Prototype Website",
        project_desc: "A personal project for my father-in-law's buisness. Prototype inlcudes a Home, Services, Contact, and Reviews page.",
        tech_stack: ['Figma'],
        project_img: Project1,
        project_url: 'https://www.figma.com/file/otvYHvEPINtoBsg2tb0HYB/WSS-Website?type=design&node-id=0%3A1&mode=design&t=UOgKTx6HnDl1OaAj-1',
        reverse: false,
    },
    {
        id: 1,
        project_name: "Capstone: Rick and Morty Trivia Game",
        project_desc: "A Capstone project that required my group to use React to create the front end website. Integration with SpringBoot framework for the backend, as well as React Native for the mobile app front end.",
        tech_stack: ['React', 'Firebase', 'Figma'],
        project_img: Project2,
        project_url: 'https://www.figma.com/file/FJWgmXGGz6XGogzRKr9qET/Untitled?type=design&mode=design&t=UOgKTx6HnDl1OaAj-1',
        reverse: true,
    }
]

export const navLinks = [
    {
        id: 0,
        name: 'Home',
        href: 'Home'
    },
    {
        id: 1,
        name: 'My Skills',
        href: 'Skills'
    },
    {
        id: 2,
        name: 'My Projects',
        href: 'Projects'
    },
    {
        id: 3,
        name: 'My Contact',
        href: 'Contact'
    }
]