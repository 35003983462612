import styled from "styled-components";

export const SkillsCardContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 2rem;
    padding: 0 5%;

    @media(max-width: ${({ theme }) => theme.breakpoints.mobile }){
        display: block;
        padding: 0;
    }
`

export const SkillsCard = styled.div`
    width: 230px;
    border: 1px solid #fff;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(72, 72, 72, 0.75) 50%, rgba(72, 72, 72, 1) 100%);
    border-radius: 25px;
    padding: 3rem 0;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
    @media(max-width: ${({ theme }) => theme.breakpoints.mobile }){
        width: 100%;
        margin-top: 2rem;
    }
`
